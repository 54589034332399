export const TICKER_PREFETCH_SIZE = '5';
export const TICKER_SCROLL_SIZE = '100';
export const TICKER_MATCH_CARD_WIDTH = 324;
export const HOMEPAGE_COLUMN_NUMBER_OF_WIDGETS = 10;
export const MATCH_CARDS_BUCKET_PATH = 'matchcards';
export const COURT_PRIORITY = [
	'cc',
	'incc',
	's',
	'gs',
	'sc',
	's1',
	'gs1',
	'sc1',
	's2',
	'gs2',
	'sc2',
	's3',
	'gs3',
	'sc3',
	's4',
	'gs4',
	'sc4',
	's5',
	'gs5',
	'sc5',
	's6',
	'gs6',
	'sc6'
];

export const USER_IMPRESSION_KEY = 'user-impression-key';
export const BLOG_LABEL = 'Pickleball Hub';
export const GOOGLE_AUTOCOMPLETE_SCRIPT_ID = 'google-script';
export const TINYMCE_EDITOR_KEY = '2fhjogepu3pkb5q18hb6hzxgg2ojov07jq1mukahjnp7y0my';

export const IS_PRODUCTION = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

export const FEATURED_PLAYERS_SINGLES = 'Singles';

export const ALLOWED_ORIGINS = [
	'http://localhost',
	'https://pickleball.com',
	'https://www.pickleball.com',
	'https://sso.pickleball.com',
	'https://www.sso.pickleball.com',
	'https://manage.pickleball.com',
	'https://www.manage.pickleball.com',
	'https://checkout.pickleball', // Sufficient, since it can be pickleball.com, pickleballclubs.com, pickleballtournaments.com, pickleballteamleagues.com and more (in all cases pickleball is the first word of the domain)
	'https://www.checkout.pickleball',
	'https://pickleballleagues.com',
	'https://www.pickleballleagues.com',
	'https://worldpickleballrankings.com',
	'https://www.worldpickleballrankings.com',
	'https://pickleballtournaments.com',
	'https://www.pickleballtournaments.com',
	'https://pickleballclubs.com',
	'https://www.pickleballclubs.com',
	'https://pickleballteamleagues.com',
	'https://www.pickleballteamleagues.com',
	'https://pickleballdev.net',
	'https://www.pickleballdev.net',
	'https://sso.pickleballdev.net',
	'https://www.sso.pickleballdev.net',
	'https://manage.pickleballdev.net',
	'https://www.manage.pickleballdev.net',
	'https://pickleballleaguesdev.net',
	'https://www.pickleballleaguesdev.net',
	'https://worldpickleballrankingsdev.net',
	'https://www.worldpickleballrankingsdev.net',
	'https://pickleballtournamentsdev.net',
	'https://www.pickleballtournamentsdev.net',
	'https://pickleballclubsdev.net',
	'https://www.pickleballclubsdev.net',
	'https://pickleballteamleaguesdev.net',
	'https://www.pickleballteamleaguesdev.net'
];
